import { createStore } from 'vuex'
import auth from './modules/auth'
import menu from './modules/menu'
import user from './modules/user'

export default createStore({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        auth, menu, user
    }
})
