const routeOrder = [
    {
        path: '/orders',
        name: 'orders',
        meta: {
            title: "Orders",
            requiresAuth: true
        },
        component: () => import('../views/order/index.vue')
    }, {
        path: '/order/product',
        name: 'order.product',
        meta: {
            title: "Order Products",
            requiresAuth: true
        },
        component: () => import('../views/order/product.vue')
    }, {
        path: '/order/product-distribution',
        name: 'order.product-distribution',
        meta: {
            title: "Product Distribution",
            requiresAuth: true
        },
        component: () => import('../views/order/distribution.vue')
    },
]
export default routeOrder