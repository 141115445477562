<template>
	<div class="wrapper">
		<ez-nav />
		<ez-menu />
		<div class="content-wrapper">
			<ez-breadcrumb />
			<section class="content pt-4">
				<div class="container-fluid">
					<slot />
				</div>
			</section>
		</div>
		<ez-footer />
		<div id="ez-overlay" style="visibility: hidden" />
	</div>
</template>

<script>
import Navbar from "./../components/Navbar.vue";
import Menubar from "./../components/Menubar.vue";
import Breadcrumb from "./../components/Breadcrumb.vue";
import Footer from "./../components/Footer.vue";
import { logout, getUser, refreshToken } from "./../helpers/auth";

export default {
    name: "Dashboard",
	components: {
		"ez-nav": Navbar,
		"ez-menu": Menubar,
		"ez-breadcrumb": Breadcrumb,
		"ez-footer": Footer,
	},
	mounted(){
		// 25 minutes request refresh token 1500000
		// if (this.$store.state.auth.isAuth) {
		// 	setInterval(function () {
		// 		this.freshToken()
		// 	}.bind(this), 1500000)
		// }
	},
	methods: {
		async freshToken() {
            try {
				const r = await refreshToken()
				if (r.data.status) {
                    this.$store.dispatch('auth/refreshAuth', r.data.auth)
                } else {
                    this.$store.dispatch('auth/logout')
                    this.$store.dispatch('user/revoke')
                    this.$router.push({
                        name: 'Login'
					})
					window.location.reload()
                }
			} catch(e) {
				this.$store.dispatch('auth/logout')
                this.$store.dispatch('user/revoke')
                this.$router.push({
                    name: 'Login'
                })
			}
        },
	},
};
</script>
