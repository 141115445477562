<template>
  <li class="nav-item dropdown d-none d-sm-block d-md-block">
    <a class="nav-link" data-toggle="dropdown" href="#">
      <i class="far fa-comments" />
      <span class="badge badge-danger navbar-badge">3</span>
    </a>
    <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
      <a href="#" class="dropdown-item">
        <div class="media">
          <img
            src="/img/avatar_40.jpg"
            alt="User Avatar"
            class="img-size-50 mr-3 img-circle"
          />
          <div class="media-body">
            <h3 class="dropdown-item-title">
              Brad Diesel
              <span class="float-right text-sm text-danger">
                <i class="fas fa-star" />
              </span>
            </h3>
            <p class="text-sm">
              Call me whenever you can...
            </p>
            <p class="text-sm text-muted">
              <i class="far fa-clock mr-1" /> 4 Hours Ago
            </p>
          </div>
        </div>
      </a>
      <div class="dropdown-divider" />
      <a href="#" class="dropdown-item">
        <div class="media">
          <img
            src="/img/avatar_40.jpg"
            alt="User Avatar"
            class="img-size-50 img-circle mr-3"
          />
          <div class="media-body">
            <h3 class="dropdown-item-title">
              John Pierce
              <span class="float-right text-sm text-muted">
                <i class="fas fa-star" />
              </span>
            </h3>
            <p class="text-sm">I got your message bro</p>
            <p class="text-sm text-muted">
              <i class="far fa-clock mr-1" /> 4 Hours Ago
            </p>
          </div>
        </div>
      </a>
      <div class="dropdown-divider" />
      <a href="#" class="dropdown-item">
        <div class="media">
          <img
            src="/img/avatar_40.jpg"
            alt="User Avatar"
            class="img-size-50 img-circle mr-3"
          />
          <div class="media-body">
            <h3 class="dropdown-item-title">
              Nora Silvester
              <span class="float-right text-sm text-warning">
                <i class="fas fa-star" />
              </span>
            </h3>
            <p class="text-sm">The subject goes here</p>
            <p class="text-sm text-muted">
              <i class="far fa-clock mr-1" /> 4 Hours Ago
            </p>
          </div>
        </div>
      </a>
      <div class="dropdown-divider" />
      <a href="#" class="dropdown-item dropdown-footer">See All Messages</a>
    </div>
  </li>
</template>
