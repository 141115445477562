import { createRouter, createWebHistory } from 'vue-router'
import routeSetting from './setting'
import routePermission from './permission'
import routeOrder from './order'
import routePrint from './print'
import routeDSP from './dsp'

const baseRoute = [
    {
        path: '/',
        name: 'Dashboard',
        meta: {
            title: 'Home',
            requiresAuth: true
        },
        component: () => import('../views/Dashboard.vue')
    }, {
        path: '/login',
        name: 'Login',
        meta: {
            title: 'Login',
            layout: 'plain'
        },
        component: () => import('../views/auth/Login.vue')
    }, {
        path: '/settings',
        name: 'settings',
        meta: {
            title: "Settings",
            requiresAuth: true
        },
        component: () => import('../views/settings/index.vue')
    }, {
        path: '/mitra',
        name: 'mitra',
        meta: {
            title: "Mitra",
            requiresAuth: true
        },
        component: () => import('../views/mitra/index.vue')
    }, {
        path: '/suplier',
        name: 'suplier',
        meta: {
            title: "Suplier",
            requiresAuth: true
        },
        component: () => import('../views/mitra/suplier.vue')
    }, {
        path: '/post-asset',
        name: 'post.asset',
        meta: {
            title: "Post Asset",
            requiresAuth: true
        },
        component: () => import('../views/post/assets.vue')
    }, {
        path: '/post',
        name: 'post',
        meta: {
            title: "Post",
            requiresAuth: true
        },
        component: () => import('../views/post/index.vue')
    }
]

const routes = baseRoute.concat(
    routeSetting,
    routeOrder,
    routePermission,
    routeDSP,
    routePrint
)

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
