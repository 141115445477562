<template>
	<li class="nav-item">
		<router-link to="/mitra" class="nav-link">
			<i class="nav-icon fas fa-store text-yellow" />
			<p>UKM</p>
		</router-link>
	</li>
    <li class="nav-item">
		<router-link to="/my-store" class="nav-link">
			<i class="nav-icon fas fa-warehouse text-primary" />
			<p>My Store</p>
		</router-link>
	</li>
    <li class="nav-item">
		<router-link to="/team" class="nav-link">
			<i class="nav-icon fa fa-users text-success" />
			<p>Team</p>
		</router-link>
	</li>
    <li class="nav-item">
		<router-link to="/my-area" class="nav-link">
			<i class="nav-icon fa fa-map-marker-alt text-purple" />
			<p>Area</p>
		</router-link>
	</li>
	<li><hr class="dropdown-divider"></li>
    <li class="nav-item">
		<router-link to="/checking-orders" class="nav-link">
			<i class="nav-icon fa fa-eye text-maroon" />
			<p>Checking Orders</p>
		</router-link>
	</li>
    <li class="nav-item">
		<router-link to="/assign-orders" class="nav-link">
			<i class="nav-icon fa fa-boxes text-yellow" />
			<p>Assign Orders</p>
		</router-link>
	</li>
	<li class="nav-item">
		<router-link to="/packet" class="nav-link">
			<i class="nav-icon fa fa-dolly text-orange" />
			<p>Packet</p>
		</router-link>
	</li>
	<li class="nav-item">
		<router-link to="/delivery/order" class="nav-link">
			<i class="nav-icon fa fa-shipping-fast text-purple" />
			<p>Deliver Order</p>
		</router-link>
	</li>
</template>