import getAuth from './../../helpers/auth';
import Cookies from "js-cookie"

const a = getAuth()
//const thirtyminute = 1 / 48
const xp = 7 // seven days

export default {
    namespaced: true,
    state: () => ({
        isAuth: a.status,
        auth_fail: 0,
        token: a.token,
        token_type: a.type,
        token_expires: a.expires_in
    }),
    mutations: {
        success(state, payload) {
            state.auth_fail = 0
            state.isAuth = true
            localStorage.setItem('auth', JSON.stringify(payload))
            Cookies.set('auth', JSON.stringify(payload), { expires: xp, path: '' })
        },
        failed(state) {
            state.isAuth = false
            state.auth_fail++
        },
        logout(state) {
            state.isAuth = false
            localStorage.removeItem('auth')
            Cookies.remove('auth', { path: '' })
            Cookies.remove('sbExpanded')
        },
        refreshToken(state, payload) {
            state.isAuth = true
            localStorage.setItem('auth', JSON.stringify(payload))
            Cookies.set('auth', JSON.stringify(payload), { expires: xp, path: '' })
        }
    },
    actions: {
        success(c, r) {
            c.commit('success', r)
        },
        failed(c) {
            c.commit('failed')
        },
        logout(c) {
            c.commit('logout')
        },
        refreshAuth(c, res) {
            c.commit('refreshToken', res)
        }
    }
}
