import axios from 'axios'
import Cookies from "js-cookie"

// axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'https://my.segarpedia.com';
let headers = {'AccessToken': process.env.VUE_APP_CLIENT}
let refresh = {}
const auth = Cookies.get('auth')

if (auth) {
    const t = JSON.parse(auth)
    if (t) {
        headers = {
            'Authorization': t.token_type + ' ' + t.token,
            'Accept': 'application/json',
            'AccessToken': process.env.VUE_APP_CLIENT
        }
        refresh = {
            'Authorization': t.token_type + ' ' + t.refresh_token,
            'Accept': 'application/json',
            'AccessToken': process.env.VUE_APP_CLIENT
        }
    }
}

const cdn = process.env.VUE_APP_CDN_HOST
const baseUrl = process.env.VUE_APP_API_HOST
const $axios = axios.create({
    baseURL: baseUrl,
    headers: headers
});
export { $axios, cdn, baseUrl, headers }
