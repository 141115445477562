const routeSetting = [
    {
        path: '/account',
        name: 'account',
        meta: {
            title: "Account",
            requiresAuth: true
        },
        component: () => import('../views/account/index.vue')
    }, {
        path: '/users',
        name: 'users',
        meta: {
            title: "Users",
            requiresAuth: true
        },
        component: () => import('../views/user/index.vue')
    }, {
        path: '/roles',
        name: 'roles',
        meta: {
            title: "Roles",
            requiresAuth: true
        },
        component: () => import('../views/role/index.vue')
    }, {
        path: '/dimensions',
        name: 'dimension',
        meta: {
            title: "Dimension",
            requiresAuth: true
        },
        component: () => import('../views/settings/dimension.vue')
    }, {
        path: '/asset',
        name: 'asset',
        meta: {
            title: "Assets",
            requiresAuth: true
        },
        component: () => import('../views/asset/index.vue')
    }, {
        path: '/categories',
        name: 'categories',
        meta: {
            title: "Categories",
            requiresAuth: true
        },
        component: () => import('../views/categories/index.vue')
    }, {
        path: '/status',
        name: 'status',
        meta: {
            title: "Status",
            requiresAuth: true
        },
        component: () => import('../views/settings/status.vue')
    }, {
        path: '/variable',
        name: 'variable',
        meta: {
            title: "Variable",
            requiresAuth: true
        },
        component: () => import('../views/settings/variable.vue')
    }, {
        path: '/products',
        name: 'product',
        meta: {
            title: "Product",
            requiresAuth: true
        },
        component: () => import('../views/product/index.vue')
    }, {
        path: '/dropship-point',
        name: 'dsp',
        meta: {
            title: "Drop ship point",
            requiresAuth: true
        },
        component: () => import('../views/settings/dsp.vue')
    }
]

export default routeSetting