const routePermission = [
    {
        path: '/permissions',
        name: 'permissions',
        meta: {
            title: "Permissions",
            requiresAuth: true
        },
        component: () => import('../views/permission/index.vue')
    }, {
        path: '/permissions/:uid/:name',
        name: 'user-permissions',
        meta: {
            title: "User Permissions",
            requiresAuth: true
        },
        component: () => import('../views/permission/user.vue')
    }
]

export default routePermission