const routePrint = [
    {
        path: '/print/informasi-pemesanan-produk',
        name: 'print.order-product',
        meta: {
            title: 'Informasi Pemesanan Produk',
            layout: 'plain'
        },
        component: () => import('../views/print/OrderProduct.vue')
    }, {
        path: '/print/distribusi-pemesanan-produk/:dsp/:order_start/:order_number',
        name: 'print.distribusi-product',
        meta: {
            title: 'Informasi Pemesanan Produk',
            layout: 'plain'
        },
        component: () => import('../views/print/DistribusiProduct.vue')
    }, {
        path: '/print/distribusi-pemesanan-produk',
        name: 'print-all.distribusi-product',
        meta: {
            title: 'Informasi Pemesanan Produk',
            layout: 'plain'
        },
        component: () => import('../views/print/DistribusiProduct.vue')
    },
]
export default routePrint

