<template>
	<section class="content-header">
		<div class="container-fluid">
			<div class="row">
				<div class="col-sm-6">
					<div class="text-muted">
						<ol class="breadcrumb">
							<li class="breadcrumb-item">
								<i class="fa fa-chevron-circle-left mt-1 text-yellow" @click="goback" style="cursor:pointer" />
							</li>
							<li class="breadcrumb-item text-success">
								{{ viewName }}
							</li>
						</ol>
					</div>
				</div>
				<div class="col-sm-6">
					<ol class="breadcrumb float-right d-none d-md-flex d-sm-flex">
						<li class="breadcrumb-item">
							<router-link to="/" class="text-maroon">
								<i class="fa fa-home" /> Home
							</router-link>
						</li>
						<li class="breadcrumb-item">
							<a href="#" class="text-orange">{{ viewName }}</a>
						</li>
					</ol>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "Breadcrumb",
	data() {
		return {
			viewName: "Dashboard",
		};
	},
	watch: {
        '$route' (to, form) {
            this.viewName = to.meta.title
        }
    },
	methods: {
		goback() {
			history.back()
		}
	}
};
</script>
