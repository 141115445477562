<template>
	<aside class="main-sidebar elevation-2 sidebar-no-expand sidebar-light-success">
		<a href="#" class="brand-link">
			<img src="/favicon.png" :alt="app.name" class="brand-image" />
			<img src="/segarpedia.png" :alt="app.name" class="brand-text" />
		</a>
		<div class="sidebar">
			<div class="user-panel mt-2 pb-0 mb-3 d-flex">
				<div class="image">
					<img
						:src="user.avatar"
						class="img-circle"
						alt="User Image"
					/>
				</div>
				<div class="info">
					<div class="d-block">
						<a href="#" class="text-success">{{ user.name }}</a>
						<div class="text-purple"> {{user.role}}</div>
						<router-link v-if="user.role=='Super Boy'" to="/settings" class="float-right text-success">
							<i class="fa fa-cog fa-spin" />
						</router-link>
					</div>
					<a href="javascript:void(0)" @click="quit">
						<small class="text-maroon">
							<i class="fa fa-power-off" /> Logout
						</small>
					</a>
				</div>
			</div>
			<nav class="mt-2">
				<ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
					<li class="nav-item">
						<router-link to="/" class="nav-link">
							<i class="nav-icon fas fa-tachometer-alt text-pink" />
							<p>Dashboard</p>
						</router-link>
					</li>
					<menu-management v-if="user.role=='Management'" />
					<menu-dsp v-if="user.role=='Kepala DSP' || user.role == 'Admin DSP'" />
					<menu-courier v-if="user.role=='Courier'" />
				</ul>
			</nav>
		</div>
	</aside>
</template>

<script>
import { logout  } from "./../helpers/auth";
import MenuManagement from './menu/management.vue'
import MenuDsp from './menu/dsp.vue'
import MenuCourier from './menu/courier.vue'

export default {
	name: "Menubar",
	components: {
		MenuManagement,
		MenuDsp,
		MenuCourier
	},
	data() {
		return {
			app: {
				name: null,
			},
			user: {
				name: null,
				avatar: null,
				role: null
			},
		};
	},
	mounted() {
        this.app.name = process.env.VUE_APP_NAME
        this.user.name = this.$store.state.user.complete_name
		this.user.role = this.$store.state.user.role
        this.user.avatar = '/img/avatar_40.jpg'
	},
	methods: {
		quit() {
			this.$confirm("Hi " + this.user.name + " do you wanna quit from this wonderful show?", "Logout", {
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                showClose: false,
                closeOnClickModal: false,
                closeOnPressEscape: false,
                lockScroll: true,
                cancelButtonClass: "negative",
                confirmButtonClass: "positive",
                center: true,
            }).then(() => {
                this.exit();
            }).catch(() => {})
		},
		async exit() {
            const loader = this.$loading({
                lock: true,
                text: "please wait...",
                spinner: "fas fa-radiation fa-spin fa-2x",
                customClass: "ez-loader-background",
            });
            try {
				// ignore error from api (if any)
				await logout()
                this.$store.dispatch('auth/logout')
                this.$store.dispatch('user/revoke')
                this.$router.push({
                    name: 'Login'
                })
				loader.close();
            } catch (e) {
                this.$store.dispatch('auth/logout')
                this.$store.dispatch('user/revoke')
                this.$router.push({
                    name: 'Login'
				})
				loader.close();
            }
		},
	},
};
</script>
