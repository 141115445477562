const au = localStorage.getItem('user')
const activeUser = au ? JSON.parse(au) : {
    username: null,
    email: null,
    phonenumber: null,
    avatar: null,
    first_name: null,
    last_name: null,
    role: null
}

export default {
    namespaced: true,
    state: () => ({
        username: activeUser.username,
        complete_name: activeUser.complete_name,
        role: activeUser.role,
        email: activeUser.email,
        phonenumber: activeUser.phonenumber,
        avatar: activeUser.avatar ? activeUser.avatar : '/img/avatar_73.jpg'
    }),
    mutations: {
        setUser(state, payload) {
            state.username = payload.username
            state.first_name = payload.first_name
            state.last_name = payload.last_name
            state.role = payload.role
            state.email = payload.email
            state.phonenumber = payload.phonenumber
            state.avatar = payload.avatar
        },
        unSetUser(state){
            state.username = null
            state.first_name = null
            state.last_name = null
            state.role = null
            state.email = null
            state.avatar = null
        }
    },
    getters: {
        getUsername(state) {
            return state.username
        }
    },
    actions: {
        assign(context, response) {
            const u = response
            localStorage.setItem('user', JSON.stringify(u))
            context.commit('setUser', u)
        },
        revoke(c) {
            localStorage.removeItem('user')
            c.commit('unSetUser')
        }
    }
}
