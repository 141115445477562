<template>
	<li class="nav-item">
		<router-link to="/delivery-list" class="nav-link">
			<i class="nav-icon fa fa-clipboard-list text-maroon" />
			<p>Barang antaran</p>
		</router-link>
	</li>
	<li class="nav-item">
		<router-link to="/delivered-list" class="nav-link">
			<i class="nav-icon fa fa-handshake text-success" />
			<p>Paket diterima</p>
		</router-link>
	</li>
</template>