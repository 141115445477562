<template>
	<footer class="main-footer">
		<div class="float-right d-none d-sm-block">
			<b>Version</b> {{ app_version }} made with
			<i class="fa fa-heartbeat text-maroon"></i>
		</div>
		<strong
			>Copyright &copy; {{ new Date().getFullYear() }}
			<a :href="app_url">{{ copy }}</a
			>.</strong
		>
		All rights reserved.
	</footer>
</template>

<script>
export default {
	name: "Footer",
	data() {
		return {
			copy: String,
			app_version: String,
			app_url: String,
		};
	},
	mounted() {
		this.copy = process.env.VUE_APP_COPY;
		this.app_version = process.env.VUE_APP_VERSION;
	},
};
</script>
