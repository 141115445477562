<template>
	<div class="wraper">
		<slot />
	</div>
</template>

<script>
export default {
    name: "Plain"
}
</script>
