const Menu = {
    namespaced: true,
    state: () => ({
        expand: false
    }),
    getters: {
        status: state => {
            return state.expand
        }
    },
    mutations: {
        expand(state) {
            state.expand = true
        },
        collapse(state) {
            state.expand = false
        }
    },
    actions: {
        expand(context) {
            context.commit('expand')
        },
        collapse(context) {
            context.commit('collapse')
        }
    }
}

export default Menu
