<template>
    <component :is="layout">
        <router-view />
    </component>
</template>

<script>
export default {
    watch: {
        '$route' (to, form) {
            document.title = process.env.VUE_APP_NAME + ' - ' + (to.meta.title || 'Know it better')
        }
    },
    computed: {
        layout() {
            return (this.$route.meta.layout || 'dashboard') + '-layout';
        }
    }
}
</script>
