<template>
	<nav class="main-header navbar navbar-expand navbar-white navbar-light">
		<ul class="navbar-nav">
			<li class="nav-item">
				<a class="nav-link text-grey" href="#" @click="expand">
					<i class="fas fa-bars" />
				</a>
			</li>
		</ul>
		
		<ul class="navbar-nav ml-auto">
			<messages />
			<notifications />
		</ul>
	</nav>
</template>

<script>
import Cookies from 'js-cookie'
import MenuMessages from './navbar/Message'
import MenuNotif from './navbar/Notif'

export default {
	name: 'Navbar',
	components:{
		messages: MenuMessages,
		notifications: MenuNotif
	},
	data() {
		return {
			isExpanded: false,
			ww: window.innerWidth,
			mobile: {
				open: 'sidebar-open',
				close: 'sidebar-closed',
			},
		}
	},
	mounted() {
		if (this.$store.state.auth.isAuth) {
			if (Cookies.get('sbExpanded')) {
				if (Cookies.get('sbExpanded') == 'expand') {
					this.isExpanded = true
					this.sb(true)
				} else {
					this.isExpanded = false
					this.sb(false)
				}
			} else {
				Cookies.set('sbExpanded', 'collapse')
				this.sb(false)
				this.isExpanded = false
			}
			window.addEventListener('resize', this.onWResize)
		}
	},
	watch: {
		ww: function (v) {
			if (v > 800) {
				this.mobile.open = ''
				this.mobile.close = ''
			} else {
				this.mobile.open = 'sidebar-open'
				this.mobile.close = 'sidebar-closed'
			}
		},
		isExpanded: function (val) {
			// console.log('isExpand from watch: ' + val)
		},
	},
	methods: {
		expand() {
			if (this.isExpanded) {
				this.sb(false)
				this.isExpanded = false
				Cookies.set('sbExpanded', 'collapse')
			} else {
				this.sb(true)
				this.isExpanded = true
				Cookies.set('sbExpanded', 'expand')
			}
		},
		sb(s) {
			$('body').removeAttr('class')
			if (s) {
				if (this.ww < 800) {
					$('#ez-overlay')
						.removeAttr('style')
						.attr('style', 'z-index: 1037')
					document
						.getElementById('ez-overlay')
						.addEventListener('click', this.expand)
				}
				$('body').attr(
					'class',
					'sidebar-mini layout-navbar-fixed text-sm layout-fixed ' +
						this.mobile.open
				)
			} else {
				if (this.ww < 800) {
					$('#ez-overlay')
						.removeAttr('style')
						.attr('style', 'visibility: hidden')
				}
				$('body').attr(
					'class',
					'sidebar-mini layout-navbar-fixed text-sm sidebar-collapse layout-fixed ' +
						this.mobile.close
				)
			}
		},
		onWResize() {
			this.ww = window.innerWidth
		},
	},
}
</script>

<style scoped>
.el-form-item {
	margin-bottom: 0px;
}
</style>
