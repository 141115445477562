<template>
	<li class="nav-item">
		<router-link :to="{name: 'orders', path: '/orders'}" class="nav-link">
			<i class="fa fa-shopping-cart nav-icon text-red"></i>
			<p>Monitoring Pembelian</p>
		</router-link>
	</li>
	<li class="nav-item has-treeview">
		<a href="#" class="nav-link">
			<i class="nav-icon fa fa-shopping-bag text-success"></i>
			<p>
				Product Order
				<i class="fas fa-angle-left right"></i>
			</p>
		</a>
		<ul class="nav nav-treeview">
			<li class="nav-item">
				<router-link :to="{path: '/order/product'}" class="nav-link">
					<i class="fa fa-user-tie nav-icon text-purple"></i>
					<p>Suplier</p>
				</router-link>
			</li>
			<li class="nav-item">
				<router-link :to="{path: '/order/product-distribution'}" class="nav-link">
					<i class="fa fa-users nav-icon text-yellow"></i>
					<p>Customers</p>
				</router-link>
			</li>
		</ul>
	</li>
	<li><hr class="dropdown-divider"></li>
	<li class="nav-item">
		<router-link :to="{name: 'mitra', path: '/mitra'}" class="nav-link">
			<i class="fa fa-store nav-icon text-teal"></i>
			<p>Mitra</p>
		</router-link>
	</li>
	<li class="nav-item">
		<router-link :to="{name: 'dsp', path: '/dropship-point'}" class="nav-link">
			<i class="fa fa-warehouse nav-icon text-orange"></i>
			<p>DSP</p>
		</router-link>
	</li>
	<li class="nav-item">
		<router-link :to="{name: 'suplier', path: '/suplier'}" class="nav-link">
			<i class="fa fa-house-user nav-icon text-purple"></i>
			<p>Suplier</p>
		</router-link>
	</li>
	<li class="nav-item">
		<router-link to="variable" class="nav-link">
			<i class="fa fa-infinity nav-icon text-maroon"></i>
			<p>Variabel</p>
		</router-link>
	</li>
	<li class="nav-item">
		<router-link to="categories" class="nav-link">
			<i class="fa fa-layer-group nav-icon text-yellow"></i>
			<p>Category Product</p>
		</router-link>
	</li>
	<li class="nav-item">
		<router-link to="products" class="nav-link">
			<i class="fa fa-spa nav-icon text-green"></i>
			<p>Products</p>
		</router-link>
	</li>
</template>