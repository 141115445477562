const routeDSP = [
    {
        path: '/my-store',
        name: 'my.store',
        meta: {
            title: 'My Store',
            requiresAuth: true
        },
        component: () => import('../views/dsp/mystore.vue')
    }, {
        path: '/team',
        name: 'dsp.team',
        meta: {
            title: 'My Team',
            requiresAuth: true
        },
        component: () => import('../views/dsp/team.vue')
    }, {
        path: '/my-area',
        name: 'dsp.area',
        meta: {
            title: 'My Area',
            requiresAuth: true
        },
        component: () => import('../views/dsp/my-area.vue')
    }, {
        path: '/checking-orders',
        name: 'checking.orders',
        meta: {
            title: 'Store Orders',
            requiresAuth: true
        },
        component: () => import('../views/dsp/checking-orders.vue')
    }, {
        path: '/assign-orders',
        name: 'dsp.assign-orders',
        meta: {
            title: 'Assign Orders',
            requiresAuth: true
        },
        component: () => import('../views/dsp/assign-orders.vue')
    }, {
        path: '/packet',
        name: 'dsp.packet',
        meta: {
            title: 'Packet',
            requiresAuth: true
        },
        component: () => import('../views/dsp/packet.vue')
    }, {
        path: '/delivery/order',
        name: 'dsp.delivery-orders',
        meta: {
            title: 'Delivery Order',
            requiresAuth: true
        },
        component: () => import('../views/dsp/delivery-order.vue')
    }, {
        path: '/delivery-list',
        name: 'delivery.list',
        meta: {
            title: "Daftar Antaran",
            requiresAuth: true
        },
        component: () => import('../views/order/delivery-list.vue')
    }, {
        path: '/delivered-list',
        name: 'delivered.list',
        meta: {
            title: "Daftar Barang Diterima",
            requiresAuth: true
        },
        component: () => import('../views/order/delivered-courier.vue')
    },
]

export default routeDSP