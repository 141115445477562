import { $axios} from './axios.conf';

const apiURI = process.env.VUE_APP_API_HOST
const authEP = process.env.VUE_APP_AUTH_ENDPOINT

export function signin(c) {
    return $axios.post(apiURI + '/signin', c)
}

export function refreshToken() {
    return $axios.get(authEP + '/refresh')
}

export function logout() {
    return $axios.get(authEP + '/logout')
}

export function requestResetPassword(p){
    return true
}

export function getUser() {
    return $axios.get(authEP + '/user')
}

export default function getAuth () {
    const d = JSON.parse(localStorage.getItem('auth'))
    return (d) ? {
        status: true,
        token: d.token,
        type: d.token_type,
        expires_in: 1
    } : {
        status: false,
        token: null,
        type: null,
        expires_in: null
    }
}
